export const FeatureKeys = {
  isExampleFlag: 'example-flag',
  bbogDiscoverExperiment: 'bb.og.discover',
  useExperimentApiRecommendations: 'use-experiment-api-recommendations',
  isPrDependenciesEnabled: 'pr-dependencies',
  isPrReviewGroupsEnabled: 'pr-review-groups',
  sumCreateWorkspacePreChangeEnabled:
    'ADMINX-1261-workspaces-list-sum-pre-change',
  customMergeChecksActivated: 'custom-merge-checks-activated',
  postOfficeInAppSwitcher: 'post-office-in-bitbucket-switcher',
} as const;

export type FeatureKeyType = (typeof FeatureKeys)[keyof typeof FeatureKeys];

export const pipelinesStatsigKeys = {
  isPipelinesStepFilterEnabled: 'enable_pipelines_step_filter',
  isImprovedLogStreamingRenderingEnabled:
    'bitbucket_pipelines_improve_log_streaming_renders_',
  isPipelinesNavigationDropdownEnabled:
    'bitbucket_pipelines_navigation_dropdown_enabled',
  buildFixAiAssistantEnabled: 'bitbucket_pipelines_build_fix_ai_assistant',
  deploymentSummaryAiAssistantEnabled:
    'bitbucket_pipelines_deployment_ai_assistant',
  enableStashCommitLink: 'pipelines-add-stash-commit-link',
  isPipelinesManualStepsInStagesEnabled:
    'bitbucket-pipelines-allow-manual-steps-in-stages',
  isStepMetricsEnabled: 'bitbucket-pipelines-step-metrics-enabled',
  isPipelineListSidebarEnabled:
    'bitbucket-pipelines-pipeline-list-sidebar-enabled',
  isPipelineRunsHistoryEnabled:
    'bitbucket-pipelines-enable-pipeline-runs-history',
  showShadowRepositoryConfiguration:
    'bitbucket-pipelines-show-shadow-repo-configuration',
  enablePersistPipelineListFilters:
    'bitbucket-pipelines-persist-pipeline-list-filters',
  isPipelinesMacOSTartRunnerEnabled: 'pipelines-mac-os-tart-runner',
  deprecatedRunnersWarningEnabled: 'pipelines_deprecated_runners_warning',
  pipelinesArtifactPreviewSizeLimit: 'pipelines-artifact-preview-size-limit',
  pipelinesMaximumAllowedStepSizeForCloudRunners:
    'pipelines-max-allowed-step-size-for-cloud-runners',
  isThirdPartySecretsProviderEnabled: 'enable_configure_secrets_provider',
  isBitbucketPipelinesYmlSupportEnabled: 'bitbucket_pipelines_yml_support',
  isExcludePathsChangesetConditionEnabled:
    'exclude_paths_changeset_condition_enabled',
  isIncreasedMaxRepoSchedulesEnabled:
    'trigger-service_increased-max-repo-schedules',
};

export const StatsigFeatureKeys = {
  ...pipelinesStatsigKeys,
  copyableBranchName: 'bbc-copyable-branch-name',
  mergeStatusStateMessageDisabled: 'bbc-merge-status-state-message-disabled',
  prCreateDeleteBranchDefault: 'bbc-frontbucket-default-delete-branch',
  enableAutoreviewFeedbackUi: 'enable_autoreview_feedback_ui',
  disableFileEditForNonWriters: 'disable-file-edit-for-non-writers',
  gpgKeysUi: 'gpg-key-user-interface',
  gpgKeysSpotlight: 'gpg-key-spotlight',
  gpgKeysEnforcementSettingsUI: 'gpg-enforcement-setting-ui',
  postOfficeIntegrationInAppSwitcher:
    'post-office-integration-in-bitbucket-switcher',
  disableCompassBitbucketRepositoryCard:
    'disable-compass-bitbucket-repository-card-statsig',
  bbcDraftPRs: 'bbc_draft_prs',
  activityFeedCommentsPreventReorder: 'activity_feed_comments_prevent_reorder',
  nextUnreviewedFile: 'bbc-frontbucket-next-unreviewed-file',
  markAllFiles: 'bbc-frontbucket-mark-all-files',
  descriptivePrUpdateFlag: 'descriptive_pr_update_flag',
  staleBranchesFilter: 'stale-branch-list-filter',
  mergeInProgressUxVisibility: 'merge-in-progress-ux-visibility',
  newSourceEditor: 'new-source-editor',
  useForgeRepoCodeOverviewPanel: 'use-forge-repo-code-overview-panel',
  autoreviewCommentUuidConfig: 'frontbucket-autoreview-comment-uuid',
  webStorageControls: 'bbc-webstorage-controls',
  codeSuggestions: 'bbc-code-suggestions',
  newSourceViewForLfs: 'frontbucket-new-source-view-for-lfs',
  autoreviewDogfoodingFlag: 'autoreview-dogfooding-flag',
} as const;

export type StatsigFeatureKeyType =
  (typeof StatsigFeatureKeys)[keyof typeof StatsigFeatureKeys];
