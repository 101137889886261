import { ROUTE_NAME, RouteFeatures, SupportedPath } from '../types';

export const getSupportedPaths = (features: RouteFeatures): SupportedPath[] => {
  const { isAccountSettingsIndexEnabled, isAccountSettingsSSHKeysEnabled } =
    features;
  const paths = [];

  if (isAccountSettingsIndexEnabled) {
    paths.push(
      {
        name: ROUTE_NAME.ACCOUNT_SETTINGS_IFRAME,
        path: '/account/settings/',
        exact: true,
      },
      {
        name: ROUTE_NAME.ACCOUNT_SETTINGS_GPG_KEYS,
        path: '/account/settings/gpg-keys/',
        exact: true,
      }
    );
    if (isAccountSettingsSSHKeysEnabled) {
      paths.push({
        name: ROUTE_NAME.ACCOUNT_SETTINGS_SSH_KEYS,
        path: '/account/settings/ssh-keys/',
        exact: true,
      });
    }

    paths.push({
      name: ROUTE_NAME.ACCOUNT_SETTINGS_IFRAME,
      path: '/account/settings/:any',
      exact: false,
    });
  }

  return paths;
};
