import { getBitValueFromBool, Logger } from '../../../common/utils';
import { ATLASSIAN_CONSENT_COOKIE_KEY, ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY, ATLASSIAN_SHOW_BANNER_COOKIE_KEY } from '../../../constants';
import { getBannerVisibility } from '../../banner-controls/get-banner-visibility';
import { setStrictlyNecessaryCookie } from '../set-strictly-necessary-cookie';
export var setConsentTokenCookie = function setConsentTokenCookie(consentToken) {
  var inTenMinutes = new Date();
  inTenMinutes.setTime(inTenMinutes.getTime() + 10 * 60 * 1000);
  setStrictlyNecessaryCookie(ATLASSIAN_CONSENT_COOKIE_KEY, consentToken, {
    expires: inTenMinutes,
    path: '/'
  });
  Logger.debug("Set Consent Token ".concat(ATLASSIAN_CONSENT_COOKIE_KEY, " ").concat(consentToken));
};
export var setConsentTokenFallbackCookie = function setConsentTokenFallbackCookie(consentToken) {
  var inOneYear = new Date();
  inOneYear.setFullYear(inOneYear.getFullYear() + 1);
  setStrictlyNecessaryCookie(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY, consentToken, {
    expires: inOneYear,
    path: '/'
  });
  Logger.debug("set fallback consent token: ".concat(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY, " ").concat(consentToken));
};
export var setShouldShowBannerCookie = function setShouldShowBannerCookie(consentToken, consentTimestamp) {
  // Since we have no good way to reference this as a global state object, we want to precompute and store the
  // banner visibility in a temporary, session-scope cookie (same expiry as the other cookies), which we can
  // lookup in the UI as needed
  var bannerVisibility = getBannerVisibility({
    consentToken: consentToken,
    consentTimestamp: consentTimestamp
  });
  var shouldShowBanner = getBitValueFromBool(!!bannerVisibility);
  var inTenMinutes = new Date();
  inTenMinutes.setTime(inTenMinutes.getTime() + 10 * 60 * 1000);
  setStrictlyNecessaryCookie(ATLASSIAN_SHOW_BANNER_COOKIE_KEY, shouldShowBanner, {
    expires: inTenMinutes,
    path: '/'
  });
  Logger.debug("set shouldShowBannerCookie: ".concat(ATLASSIAN_SHOW_BANNER_COOKIE_KEY, " ").concat(shouldShowBanner));
};
export var setRequiredConsentCookies = function setRequiredConsentCookies(consentToken, consentTimestamps) {
  setConsentTokenCookie(consentToken);
  setShouldShowBannerCookie(consentToken, consentTimestamps);
};