import { createResource } from 'react-resource-router';

import {
  setCurrentPipeline,
  clearCurrentPipeline,
} from 'src/components/pipelines/redux/actions/pipelines';
import { getPipelineUrl } from 'src/components/pipelines/redux/sagas/pipelines';
import { getCurrentPipeline } from 'src/components/pipelines/redux/selectors/pipelines';
import { ResourceContext } from 'src/router/types';

import { fetchData, hasFetchedPipeline } from './utils';

export type Params = {
  pipelineUuid: string;
  stepUuid: string;
  repositoryFullSlug: string;
  trigger: string;
};

export const getUrl = (params: Params): string => {
  return getPipelineUrl(params.repositoryFullSlug, params.pipelineUuid);
};

export const loadCurrentPipelineResource = createResource({
  type: 'currentPipeline',
  getKey: ({ match: { params } }) => getUrl(params as Params),
  maxAge: 0,
  getData: async ({ match: { params } }, { reduxStore }: ResourceContext) => {
    const { dispatch, getState } = reduxStore;

    // Prevent refetching when url changes but pipeline hasn't changed
    // e.g. switching pipeline runs or selecting a step
    if (hasFetchedPipeline(getState())) {
      return { status: 'success' };
    }

    dispatch(clearCurrentPipeline());
    const data = await fetchData(
      getUrl(params as Params),
      'current_pipeline_resource_exception'
    );

    /*
      Current pipeline state is merged with current selected pipeline run in result-page.
      It's not guaranteed if the pipeline request or the runs request will complete first,
      so we need to handle either case and ensure any data not in runs response is maintained.
    */
    if (!hasFetchedPipeline(getState())) {
      // Pipeline not already set in state from sync'd runs, set data
      dispatch(setCurrentPipeline(data));
    } else {
      // Pipeline already in state from sync'd runs, ensure data not in runs response is maintained
      dispatch(
        setCurrentPipeline({
          ...getCurrentPipeline(getState()),
          configuration_sources: data.configuration_sources,
        })
      );
    }
    return { status: 'success' };
  },
});
