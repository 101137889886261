import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { unstable_IdlePriority as idlePriority, unstable_scheduleCallback as scheduleCallback } from 'scheduler';
import { startLighthouseObserver } from '../additional-payload';
import { setUFOConfig } from '../config';
import { experimentalVC, sinkExperimentalHandler } from '../create-experimental-interaction-metrics-payload';
import { setupHiddenTimingCapture } from '../hidden-timing';
import { postInteractionLog, sinkInteractionHandler, sinkPostInteractionLogHandler } from '../interaction-metrics';
import { getVCObserver } from '../vc';
var initialized = false;
function sinkInteraction(instance, payloadPackage) {
  sinkInteractionHandler(function (interactionId, interaction) {
    scheduleCallback(idlePriority, function () {
      var payloads = payloadPackage.createPayloads(interactionId, interaction);
      payloads === null || payloads === void 0 || payloads.forEach(function (payload) {
        instance.sendOperationalEvent(payload);
      });
    });
  });
}
function sinkExperimentalInteractionMetrics(instance, payloadPackage) {
  sinkExperimentalHandler(function (interactionId, interaction) {
    scheduleCallback(idlePriority, function () {
      var payload = payloadPackage.createExperimentalMetricsPayload(interactionId, interaction);
      if (payload) {
        instance.sendOperationalEvent(payload);
      }
    });
  });
}
function sinkPostInteractionLog(instance, createPostInteractionLogPayload) {
  sinkPostInteractionLogHandler(function (logOutput) {
    scheduleCallback(idlePriority, function () {
      var payload = createPostInteractionLogPayload(logOutput);
      if (payload) {
        instance.sendOperationalEvent(payload);
      }
    });
  });
}
export var init = function init(analyticsWebClientAsync, config) {
  var _config$vc;
  if (initialized) {
    return;
  }
  setUFOConfig(config);
  if ((_config$vc = config.vc) !== null && _config$vc !== void 0 && _config$vc.enabled) {
    var _config$experimentalI;
    var vcOptions = {
      heatmapSize: config.vc.heatmapSize,
      oldDomUpdates: config.vc.oldDomUpdates,
      devToolsEnabled: config.vc.devToolsEnabled,
      selectorConfig: config.vc.selectorConfig
    };
    getVCObserver(vcOptions).start({
      startTime: 0
    });
    postInteractionLog.initializeVCObserver(vcOptions);
    postInteractionLog.startVCObserver({
      startTime: 0
    });
    if (config !== null && config !== void 0 && (_config$experimentalI = config.experimentalInteractionMetrics) !== null && _config$experimentalI !== void 0 && _config$experimentalI.enabled) {
      experimentalVC.initialize(vcOptions).start({
        startTime: 0
      });
    }
  }
  setupHiddenTimingCapture();
  startLighthouseObserver();
  initialized = true;
  Promise.all([analyticsWebClientAsync, import( /* webpackChunkName: "create-payloads" */'../create-payload'), import( /* webpackChunkName: "create-post-interaction-log-payload" */'../create-post-interaction-log-payload')]).then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
      awc = _ref2[0],
      payloadPackage = _ref2[1],
      createPostInteractionLogPayloadPackage = _ref2[2];
    if (awc.getAnalyticsWebClientPromise) {
      awc.getAnalyticsWebClientPromise().then(function (client) {
        var _config$experimentalI2, _config$postInteracti;
        var instance = client.getInstance();
        sinkInteraction(instance, payloadPackage);
        if (config !== null && config !== void 0 && (_config$experimentalI2 = config.experimentalInteractionMetrics) !== null && _config$experimentalI2 !== void 0 && _config$experimentalI2.enabled) {
          sinkExperimentalInteractionMetrics(instance, payloadPackage);
        }
        if ((_config$postInteracti = config.postInteractionLog) !== null && _config$postInteracti !== void 0 && _config$postInteracti.enabled) {
          sinkPostInteractionLog(instance, createPostInteractionLogPayloadPackage.default);
        }
      });
    } else if (awc.sendOperationalEvent) {
      var _config$experimentalI3, _config$postInteracti2;
      sinkInteraction(awc, payloadPackage);
      if (config !== null && config !== void 0 && (_config$experimentalI3 = config.experimentalInteractionMetrics) !== null && _config$experimentalI3 !== void 0 && _config$experimentalI3.enabled) {
        sinkExperimentalInteractionMetrics(awc, payloadPackage);
      }
      if ((_config$postInteracti2 = config.postInteractionLog) !== null && _config$postInteracti2 !== void 0 && _config$postInteracti2.enabled) {
        sinkPostInteractionLog(awc, createPostInteractionLogPayloadPackage.default);
      }
    }
  });
};