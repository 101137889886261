/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { TouchScrollable } from 'react-scrolllock';
import { useModal } from './hooks';
import ScrollContainer from './internal/components/scroll-container';
import { keylineHeight } from './internal/constants';
import useScroll from './internal/hooks/use-scroll';
var bodyStyles = css({
  /* This ensures the body fills the whole space between header and footer. */
  flex: '1 1 auto'
});

/**
 * Adding the padding here avoids cropping the keyline on its sides.
 * The combined vertical spacing is maintained by subtracting the
 * keyline height from header and footer using negative margins.
 */
var bodyScrollStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  paddingBlock: keylineHeight,
  paddingInline: "var(--ds-space-300, 24px)"
});

/**
 * Keylines will not be shown if scrolling in viewport so we do
 * not account for them in this case.
 */
var viewportScrollStyles = css({
  paddingInline: "var(--ds-space-300, 24px)"
});
/**
 * __Modal body__
 *
 * A modal body is used to display the main content of a modal.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples)
 * - [Code](https://atlassian.design/components/modal-dialog/code#modal-body-props)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalBody = function ModalBody(props) {
  var children = props.children,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    modalTestId = _useModal.testId;
  var shouldScrollInViewport = useScroll();
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--body");
  return shouldScrollInViewport ? jsx("div", {
    css: [bodyStyles, viewportScrollStyles],
    "data-testid": testId
  }, children) : jsx(TouchScrollable, null, jsx(ScrollContainer, {
    testId: userDefinedTestId || modalTestId
  }, jsx("div", {
    css: [bodyStyles, bodyScrollStyles],
    "data-testid": testId
  }, children)));
};
export default ModalBody;