import { colors } from '@atlaskit/theme';

export enum DeploymentsWebsocketEvent {
  DeploymentsUpdated = 'deployment_updated',
  EnvironmentsUpdated = 'environment_updated',
  EnvironmentsLockUpdated = 'environment_lock_updated',
  EnvironmentDashboardUpdated = 'environment_dashboard_updated',
}

export const DeploymentsWebsocketEvents = Object.values(
  DeploymentsWebsocketEvent
).filter(value => typeof value === 'string');

/**
 * Pipelines ws events used in redux.
 * Note: all of these events will be subscribed automatically in the sagas.
 */
export enum PipelinesWebsocketEvent {
  PipelineUpdated = 'pipeline_updated',
  CheckRunUpdated = 'check_run_updated',
  StepUpdated = 'step_updated',
  ReportUpdated = 'report_updated',
  LogUpdated = 'log_updated',
}

export enum MetricUsageStatusType {
  Warning = 'Warning',
  Error = 'Error',
  Unknown = 'Unknown',
}

export enum PipelineFillState {
  Success = 'SUCCESS',
  Skipped = 'SKIPPED',
  Failure = 'FAILURE',
  Other = 'OTHER',
}

/**
 * Pipelines ws events that will be used in react hooks.
 */
export enum PipelinesWebsocketHookEvent {
  LogUpdated = 'log_updated',
  RunnerUpdated = 'runner_updated',
  PipelineUpdated = 'pipeline_updated',
  PipelineCreated = 'pipeline_created',
}

export enum EnvironmentWebSocketEvent {
  EnvironmentsUpdated = 'environment_updated',
  EnvironmentsLockUpdated = 'environment_lock_updated',
}

export const PipelinesWebsocketEvents = Object.values(
  PipelinesWebsocketEvent
).filter(value => typeof value === 'string');

export const EnvironmentWebSocketEvents = Object.values(
  EnvironmentWebSocketEvent
).filter(value => typeof value === 'string');

export enum MetricWebsocketEvent {
  MetricUpdated = 'metric_updated',
}

export const MAX_ENV_FREE_PLAN = 10;
export const MAX_ENV_PAID_PLAN = 100;
export const MAX_ENV_PREMIUM_PLAN = 100;

export const PIPELINES_SENTRY_PRIMARY_TAG = 'pipelines';
export const BRANCH_RESTRICTIONS_PAGE_SIZE = 100;
export const VARIABLES_PAGELEN = 100;
export const PIPELINE_PAGE_SIZE = 20;
export const PIPELINE_CREATORS_PAGE_SIZE = 100;
export const SCHEDULES_PAGE_SIZE = 100;
export const QUERY_BRANCHES_PAGE_SIZE = 100;
export const PIPELINE_DEFINITIONS_PAGE_SIZE = 100;
export const ALLOWANCE_LIMIT = 100;
export const METRICS_WARNING_LIMIT = 90;
export const STEPS_PAGE_SIZE = 100;
export const PIPELINE_RUNS_PAGE_SIZE = 10;
export const TEST_REPORTS_PAGE_SIZE = 100;
export const ANNOTATIONS_PAGE_SIZE = 100;
export const METRICS_OOM_FAILURE_TRESHOLD_IN_MB = 10;
export const METRICS_USAGE_ERROR_LIMIT = 99;
export const MAX_LOG_CHUNK_SIZE = 1000000;
export const MAX_WORKER_TIMEOUT_LIMIT = 500000;
export const FREE_REPORTS_LIMIT = 3;
export const DELAY_LOG_UPDATED = 1000;
export const DELAY_PIPELINE_UPDATED = 500;
export const DELAY_STEP_UPDATED = 500;
export const KNOWN_HOSTS_PAGELEN = 100;
export const RUNNERS_PAGELEN = 10;
export const RUNNERS_WS_DEBOUNCE = 5000;
export const SELECTED_BRANCH_REGEX = /results\/branch\/(.*)\/page\/.*/;
export const PIPELINES_ONBOARDED_KEY = 'pipelines-onboarded';
export const DEPLOYMENTS_ONBOARDED_KEY = 'deployments-onboarded';
export const STEP_VIEWER_FULLSCREEN_KEY = 'step-viewer-fullscreen';
export const VIEW_BUILD_DOCTOR = 'pipelines_view_build_doctor';
export const ALLOWANCE_DISMISSED_KEY = 'allowance-dismissed';
export const STREAMING_REQUEST_INTERVAL = 1000;
export const DOCS_OIDC =
  'https://support.atlassian.com/bitbucket-cloud/docs/integrate-pipelines-with-resource-servers-using-oidc/';
export const DOCS_SSH_KEYS =
  'https://support.atlassian.com/bitbucket-cloud/docs/using-ssh-keys-in-bitbucket-pipelines/';
export const DOCS_ACCOUNT_VARIABLE =
  'https://support.atlassian.com/bitbucket-cloud/docs/variables-and-secrets/';
export const DOCS_DEPLOYMENTS =
  'https://support.atlassian.com/bitbucket-cloud/docs/set-up-and-monitor-bitbucket-deployments/';
export const DOCS_RUNNERS =
  'https://support.atlassian.com/bitbucket-cloud/docs/runners/';
export const CHANGELOG_URL = `${DOCS_RUNNERS}#Runner-Changelog`;
export const TRUNCATION_OFFSET = 6;
export const MAX_UNTRUNCATED_LENGTH = 15;
export const ALLOWANCE_THRESHOLD = 80;
export const SYNC_STATUS: {
  [key: string]: string;
} = {
  PARSING: 'PARSING',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
};
export const DEFAULT_TEMPLATES_URL =
  'https://api.bitbucket.org/2.0/repositories/bitbucketpipelines/official-templates/src/master/templates.prod.json';
export const ADDON_KEY_PREFIX = 'pipelines-';
export const ORIGIN = 'https://bitbucket.org';
export const YML_FILE_NAME = 'bitbucket-pipelines.yml';
export const DOCS_ALPHA_URL = 'https://confluence.atlassian.com/x/jW5LN';
export const FEEDBACK_EMAIL = 'pipelines-feedback@atlassian.com';
export const DOCS_YML_URL = 'https://confluence.atlassian.com/x/noU5Lw';
export const DOCS_YML_DOCKER_URL = 'https://confluence.atlassian.com/x/kYU5Lw';
export const TIME_LIMIT_EXCEEDED_STEP_URL =
  'https://confluence.atlassian.com/bbkb/troubleshooting-bitbucket-pipelines-1141505226.html#TroubleshootingBitbucketPipelines-scenario4';
export const MEMORY_LIMIT_EXCEEDED_STEP_URL =
  'https://confluence.atlassian.com/bbkb/troubleshooting-bitbucket-pipelines-1141505226.html#TroubleshootingBitbucketPipelines-scenario12';
export const STEP_SIZE_LIMIT_URL =
  'https://support.atlassian.com/bitbucket-cloud/docs/step-options/#The-Step-property';
export const PARALLEL_STEP_SIZE_LIMIT_URL =
  'https://support.atlassian.com/bitbucket-cloud/docs/parallel-step-options/#Parallel';
export const ALLOWED_PLAN_URL =
  'https://support.atlassian.com/bitbucket-cloud/docs/limitations-of-bitbucket-pipelines/';
export const PLAN_AND_BILLING_URL =
  'https://support.atlassian.com/bitbucket-cloud/docs/manage-your-plan-and-billing';
export const MEMORY_LIMIT_URL =
  'https://support.atlassian.com/bitbucket-cloud/docs/databases-and-service-containers/#Service-memory-limits';
export const DOCS_SERVICE_CONTAINERS =
  'https://confluence.atlassian.com/x/gC8kN';
export const SUPPORT_PORTAL =
  'https://getsupport.atlassian.com/servicedesk/customer/portal/11';
export const DOCS_CONFIGURE_CLONE_DEPTH =
  'https://confluence.atlassian.com/bitbucket/configure-bitbucket-pipelines-yml-792298910.html#Configurebitbucket-pipelines.yml-ci_depth';
export const DOCS_SETUP_DEPLOYMENT_GATING =
  'https://support.atlassian.com/jira-service-management-cloud/docs/set-up-deployment-gating/';
export const SERVICE_CONTAINERS =
  'https://support.atlassian.com/bitbucket-cloud/docs/databases-and-service-containers/';
export const DOCS_DEPLOYMENT_CONCURRENCY =
  'https://confluence.atlassian.com/x/3RJsO';
export const DOCS_DEPENDENCY_CACHES_URL =
  'https://confluence.atlassian.com/x/bA1hNQ';
export const DOCS_SCHEDULED_PIPELINES =
  'https://confluence.atlassian.com/x/rqadNw';
export const VALIDATOR_URL =
  'https://bitbucket.org/product/pipelines/validator';
export const HOSTNAME_PORT_INBOUND_REGEX = '\\[(.+)\\]:(\\d+)';
export const HOSTNAME_PORT_OUTBOUND_REGEX = '(.+):(\\d+)';
export const LOG_LINE_WRAP_KEY = 'pipelines_log_line_wrapped';
export const EMPTY_STEP = '{}';
export const MAX_STEP_DURATION_IN_MINUTES = 720;

// log viewer colors
export const LOG_VIEW_UPDATED_TEXT_COLOR = colors.DN900;
export const LOG_VIEW_UPDATED_BG_COLOR = '#161A1D'; // elevation.surface.sunken in dark mode
export const UPDATED_SELECTED_COMMAND_COLOR = '#579DFF';
export const UPDATED_SELECTED_COMMAND_BG_COLOR = 'rgb(32,42,64)';
export const DEFAULT_TEXT_COLOR = '#000';
export const DEFAULT_LINK_COLOR = '#336ea9';
export const DEFAULT_DANGER_COLOR = '#d44';
export const DEFAULT_SUCCESS_COLOR = '#292';

export const LOG_VIEW_LINE_TEXT_COLOR = colors.DN800;
export const LOG_VIEW_TEXT_COLOR_LIGHTER = colors.DN200;
export const LOG_VIEW_ICON_COLOR = '#9FADBC';
export const LOG_VIEW_BACKGROUND_COLOR = colors.DN30;
export const LOG_VIEW_HEADING_COLOR = colors.DN600;
export const LOG_VIEW_DISABLED_BUTTON_COLOR = colors.DN100;

// dynamic pipelines
export const PERMISSION_DENIED = 'PERMISSION_DENIED';
export const PARENT_STEP_TRIGGER = 'pipeline_trigger_parent_step';
