import React from 'react';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import store from 'src/utils/store';

export const AUTOREVIEW_DOGFOODING_FLAG_STORE_KEY =
  'autoreview.dogfooding.flag.seen';

export const AUTOREVIEW_DOGFOODING_FLAG_ID = 'autoreview-dogfooding' as const;

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const AutoreviewDogfoodingFlag = ({
  id,
  handleDismissed,
}: Props): JSX.Element => {
  const description = (
    <span>
      Hello, Atlassian staff! Autoreview is now enabled for this workspace and
      will run by default on all pull requests. To learn more, give feedback, or
      opt out a repository, please visit the{' '}
      <a
        target="_blank"
        href="https://hello.atlassian.net/wiki/spaces/AIDO/pages/4553375840/Autoreview+Dogfooding+Guide"
      >
        dogfooding guide
      </a>
      .
    </span>
  );
  return (
    <Flag
      id={id}
      icon={
        <InfoIcon
          primaryColor={token('color.icon.information', colors.B400)}
          label="Info"
        />
      }
      title="Autoreview enabled for dogfooding"
      description={description}
      onDismissed={() => {
        store.set(AUTOREVIEW_DOGFOODING_FLAG_STORE_KEY, true);
        handleDismissed(AUTOREVIEW_DOGFOODING_FLAG_ID);
      }}
    />
  );
};
