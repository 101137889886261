import {
  Cache,
  LogRequestMeta,
  LogRequestType,
  Validator,
} from 'src/components/pipelines/models';
import { createAsyncAction } from 'src/redux/actions';

import { MAX_LOG_CHUNK_SIZE } from '../../constants';

import { prefixed } from './common';

export const WEBSOCKET_RECONNECT = prefixed('WEBSOCKET_RECONNECT');
export const WEBSOCKET_SUBSCRIBE = prefixed('WEBSOCKET_SUBSCRIBE');
export const WEBSOCKET_UNSUBSCRIBE = prefixed('WEBSOCKET_UNSUBSCRIBE');

export const CLEAR_BUILD_CONFIGURATION = 'CLEAR_BUILD_CONFIGURATION';

export const CLEAR_CURRENT_PIPELINE = 'CLEAR_CURRENT_PIPELINE';

export const CLEAR_STEPS = 'CLEAR_STEPS';

export const VERIFY_PIPELINES_INSTALLATION = 'VERIFY_PIPELINES_INSTALLATION';

export const REMOVE_PIPELINES_DATA = 'REMOVE_PIPELINES_DATA';

export const SET_REPOSITORY = prefixed('SET_REPOSITORY');

export const SET_VALIDATOR = prefixed('SET_VALIDATOR');

export const PIPELINE_UPDATED = 'PIPELINE_UPDATED';

export const STEP_UPDATED = 'STEP_UPDATED';

export const LOG_UPDATED = 'LOG_UPDATED';

export const METRIC_UPDATED = 'METRIC_UPDATED';

export const REPORT_UPDATED = 'REPORT_UPDATED';

export const EXPAND_COMMAND = 'EXPAND_COMMAND';

export const COLLAPSE_COMMAND = 'COLLAPSE_COMMAND';

export const RESET_DOWNLOADED_COMMANDS = 'RESET_DOWNLOADED_COMMANDS';

export const COLLAPSE_ALL_COMMANDS = 'COLLAPSE_ALL_COMMANDS';

export const EXPAND_TEST_CASE = 'EXPAND_TEST_CASE';

export const COLLAPSE_TEST_CASE = 'COLLAPSE_TEST_CASE';

export const REQUEST_ALLOWANCE = createAsyncAction(
  prefixed('REQUEST_ALLOWANCE')
);

export const REQUEST_CHECK_SUITES = createAsyncAction(
  prefixed('REQUEST_CHECK_SUITES')
);

export const REQUEST_CHECK_SUITE = createAsyncAction(
  prefixed('REQUEST_CHECK_SUITE')
);

export const REQUEST_CHECK_RUNS = createAsyncAction(
  prefixed('REQUEST_CHECK_RUNS')
);

export const REQUEST_CHECK_RUN = createAsyncAction(
  prefixed('REQUEST_CHECK_RUN')
);

export const REQUEST_CHECKS_DEPLOYMENTS = createAsyncAction(
  prefixed('REQUEST_CHECKS_DEPLOYMENTS')
);

export const CHECK_RUN_UPDATED = 'CHECK_RUN_UPDATED';

export const REQUEST_CACHES = createAsyncAction(prefixed('REQUEST_CACHES'));

export const REQUEST_COMMIT_PULL_REQUESTS = createAsyncAction(
  prefixed('REQUEST_COMMIT_PULL_REQUESTS')
);

export const REQUEST_DELETE_CACHE = createAsyncAction(
  prefixed('REQUEST_DELETE_CACHE')
);

export const REQUEST_DELETE_CACHES = createAsyncAction(
  prefixed('REQUEST_DELETE_CACHES')
);

export const REQUEST_SCHEDULES = createAsyncAction(
  prefixed('REQUEST_SCHEDULES')
);

export const REQUEST_DELETE_SCHEDULE = createAsyncAction(
  prefixed('REQUEST_DELETE_SCHEDULE')
);

export const REQUEST_CREATE_SCHEDULE = createAsyncAction(
  prefixed('REQUEST_CREATE_SCHEDULE')
);

export const REQUEST_TOGGLE_SCHEDULE = createAsyncAction(
  prefixed('REQUEST_TOGGLE_SCHEDULE')
);

export const REQUEST_BUILD_CONFIGURATION = createAsyncAction(
  prefixed('REQUEST_BUILD_CONFIGURATION')
);

export const REQUEST_REPOSITORY_USER_IS_MEMBER = createAsyncAction(
  prefixed('REQUEST_REPOSITORY_USER_IS_MEMBER')
);

export const REQUEST_REPOSITORY_BRANCH_RESTRICTIONS = createAsyncAction(
  prefixed('REQUEST_REPOSITORY_BRANCH_RESTRICTIONS')
);

export const REQUEST_STEP_BRANCH_RESTRICTIONS = createAsyncAction(
  prefixed('REQUEST_STEP_BRANCH_RESTRICTIONS')
);

export const REQUEST_REPOSITORY_FILES = createAsyncAction(
  prefixed('REQUEST_REPOSITORY_FILES')
);

export const REQUEST_ENABLE_PIPELINES = createAsyncAction(
  prefixed('REQUEST_ENABLE_PIPELINES')
);

export const REQUEST_DISABLE_PIPELINES = createAsyncAction(
  prefixed('REQUEST_DISABLE_PIPELINES')
);

export const REQUEST_COMMIT_CONFIGURATION = createAsyncAction(
  prefixed('REQUEST_COMMIT_CONFIGURATION')
);

export const REQUEST_OFFICIAL_TEMPLATES = createAsyncAction(
  prefixed('REQUEST_OFFICIAL_TEMPLATES')
);

export const REQUEST_PIPELINES = createAsyncAction(
  prefixed('REQUEST_PIPELINES')
);

export const REQUEST_UPDATE_PIPELINE = createAsyncAction(
  prefixed('REQUEST_UPDATE_PIPELINE')
);

export const REQUEST_CURRENT_PIPELINE = createAsyncAction(
  prefixed('REQUEST_CURRENT_PIPELINE')
);

export const REQUEST_CREATE_PIPELINE = createAsyncAction(
  prefixed('REQUEST_CREATE_PIPELINE')
);

export const REQUEST_STOP_PIPELINE = createAsyncAction(
  prefixed('REQUEST_STOP_PIPELINE')
);

export const REQUEST_STEPS = createAsyncAction(prefixed('REQUEST_STEPS'));
export const REQUEST_STEP_LOG_RANGE = createAsyncAction(
  prefixed('REQUEST_STEP_LOG_RANGE')
);

export const REQUEST_COMMANDS_METADATA = createAsyncAction(
  prefixed('REQUEST_COMMANDS_METADATA')
);

export const REQUEST_RERUN_STEPS = createAsyncAction(
  prefixed('REQUEST_RERUN_STEPS')
);

export const REQUEST_ARTIFACTS = createAsyncAction(
  prefixed('REQUEST_ARTIFACTS')
);

export const REQUEST_REPORT = createAsyncAction(prefixed('REQUEST_REPORT'));
export const REQUEST_REPORTS = createAsyncAction(prefixed('REQUEST_REPORTS'));

export const REQUEST_ANNOTATIONS = createAsyncAction(
  prefixed('REQUEST_ANNOTATIONS')
);

export const REQUEST_FAILED_TEST_CASES = createAsyncAction(
  prefixed('REQUEST_FAILED_TEST_CASES')
);

export const REQUEST_TEST_CASE_REASON = createAsyncAction(
  prefixed('REQUEST_TEST_CASE_REASON')
);

export const REQUEST_METRICS = createAsyncAction(prefixed('REQUEST_METRICS'));

export const REQUEST_FLAT_NESTED_LOGS = createAsyncAction(
  prefixed('REQUEST_FLAT_NESTED_LOGS')
);

export const REQUEST_FULL_LOG = createAsyncAction(prefixed('REQUEST_FULL_LOG'));

export const REQUEST_LOG = createAsyncAction(prefixed('REQUEST_LOG'));

export const REQUEST_LOG_THROTTLED = createAsyncAction(
  prefixed('REQUEST_LOG_THROTTLED')
);

export const REQUEST_SERVICE_LOG = createAsyncAction(
  prefixed('REQUEST_SERVICE_LOG')
);

export const REQUEST_DELETE_LOG = createAsyncAction(
  prefixed('REQUEST_DELETE_LOG')
);

export const REQUEST_START_STEP = createAsyncAction(
  prefixed('REQUEST_START_STEP')
);

export const REQUEST_STEP_PROMOTION = createAsyncAction(
  prefixed('REQUEST_STEP_PROMOTION')
);

export const REQUEST_STEP_REDEPLOYMENT = createAsyncAction(
  prefixed('REQUEST_STEP_REDEPLOYMENT')
);

export const REQUEST_REDEPLOY_STEP = createAsyncAction(
  prefixed('REQUEST_REDEPLOY_STEP')
);

export const REQUEST_RESUME_STAGE_REDEPLOY = createAsyncAction(
  prefixed('REQUEST_RESUME_STAGE_REDEPLOY')
);

export const SEARCH_LOG = createAsyncAction(prefixed('SEARCH_LOG'));

export const DOWNLOAD_ALL_COMMANDS = createAsyncAction(
  prefixed('DOWNLOAD_ALL_COMMANDS')
);

export const REQUEST_LOG_RANGES = createAsyncAction(
  prefixed('REQUEST_LOG_RANGES')
);

export const SEARCH_LOG_NEXT_LINE = 'SEARCH_LOG_NEXT_LINE';
export const SEARCH_LOG_PREV_LINE = 'SEARCH_LOG_PREV_LINE';

export const ENABLE_LOG_SEARCH = 'ENABLE_LOG_SEARCH';
export const DISABLE_LOG_SEARCH = 'DISABLE_LOG_SEARCH';

export const SET_STEP = 'SET_STEP';

// Actions for setting data loaded from resources
export const SET_STEPS = 'SET_STEPS';
export const SET_PIPELINES = 'SET_PIPELINES';
export const SET_PIPELINES_LOADING = 'SET_PIPELINES_LOADING';
export const SET_CURRENT_PIPELINE = 'SET_CURRENT_PIPELINE';
export const SET_PIPELINE_CREATORS = 'SET_PIPELINE_CREATORS';

export const webSocketSubscribe = (event: string, criteria?: {}) => ({
  type: WEBSOCKET_SUBSCRIBE,
  payload: { event, criteria },
});

export const webSocketUnsubscribe = (event: string, criteria?: {}) => ({
  type: WEBSOCKET_UNSUBSCRIBE,
  payload: { event, criteria },
});

export const requestAllowance = (accountUuid: string) => {
  return {
    type: REQUEST_ALLOWANCE.REQUEST,
    meta: { accountUuid },
  };
};

export const requestCaches = () => {
  return { type: REQUEST_CACHES.REQUEST };
};

export const requestDeleteCache = (cache: Cache, cacheUuid: string) => {
  return {
    type: REQUEST_DELETE_CACHE.REQUEST,
    meta: { cache, cacheUuid },
  };
};

export const requestDeleteCaches = (cache: Cache) => {
  return {
    type: REQUEST_DELETE_CACHES.REQUEST,
    meta: { cache },
  };
};

export const requestSchedules = () => {
  return { type: REQUEST_SCHEDULES.REQUEST, meta: {} };
};

export const requestCreateSchedule = (schedule: any) => {
  return { type: REQUEST_CREATE_SCHEDULE.REQUEST, meta: { schedule } };
};

export const requestToggleSchedule = (schedule: any) => {
  return { type: REQUEST_TOGGLE_SCHEDULE.REQUEST, meta: { schedule } };
};

export const requestDeleteSchedule = (schedule: any) => {
  return { type: REQUEST_DELETE_SCHEDULE.REQUEST, meta: { schedule } };
};

export const clearBuildConfiguration = () => {
  return { type: CLEAR_BUILD_CONFIGURATION, meta: {} };
};

export const requestBuildConfiguration = (
  ref?: string,
  isBitbucketPipelinesYmlSupportEnabled?: boolean
) => {
  return {
    type: REQUEST_BUILD_CONFIGURATION.REQUEST,
    meta: { ref, isBitbucketPipelinesYmlSupportEnabled },
  };
};

export const requestRepositoryUserIsMember = (role = 'admin') => {
  return { type: REQUEST_REPOSITORY_USER_IS_MEMBER.REQUEST, meta: { role } };
};

export const requestRepositoryBranchRestrictions = (
  userUuid?: string | null
) => {
  return {
    type: REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.REQUEST,
    meta: { userUuid },
  };
};

export const requestEnablePipelines = () => {
  return { type: REQUEST_ENABLE_PIPELINES.REQUEST };
};

export const requestDisablePipelines = () => {
  return { type: REQUEST_DISABLE_PIPELINES.REQUEST };
};

export const requestCommitConfiguration = (
  configuration: string,
  commitMessage: string
) => {
  return {
    type: REQUEST_COMMIT_CONFIGURATION.REQUEST,
    meta: { configuration, commitMessage },
  };
};

export const requestOfficialTemplates = () => {
  return {
    type: REQUEST_OFFICIAL_TEMPLATES.REQUEST,
    meta: {},
  };
};

export const setValidator = (payload: Partial<Validator>) => {
  return { type: SET_VALIDATOR, payload };
};

export const removePipelinesData = () => {
  return { type: REMOVE_PIPELINES_DATA };
};

export const requestPipelines = (queryParams?: object) => {
  return { type: REQUEST_PIPELINES.REQUEST, meta: { queryParams } };
};

export const setPipelines = (payload: any, fetchCount: number) => {
  return { type: SET_PIPELINES, payload: { ...payload, fetchCount } };
};

export const setPipelinesLoading = () => {
  return { type: SET_PIPELINES_LOADING };
};

export const setCurrentPipeline = (payload: any) => {
  return { type: SET_CURRENT_PIPELINE, payload };
};

export const setSteps = (
  payload: any,
  stepUuid: string | null | undefined,
  pipelineRunUuid: string | null | undefined
) => {
  return { type: SET_STEPS, payload, meta: { stepUuid, pipelineRunUuid } };
};

export const setPipelineCreators = (payload: any) => {
  return { type: SET_PIPELINE_CREATORS, payload };
};

export const requestCurrentPipeline = (pipelineUuid: string) => {
  return { type: REQUEST_CURRENT_PIPELINE.REQUEST, meta: { pipelineUuid } };
};

export const clearCurrentPipeline = () => {
  return { type: CLEAR_CURRENT_PIPELINE };
};

export const clearSteps = () => {
  return { type: CLEAR_STEPS };
};

export const requestCreatePipeline = (targetPipeline: object) => {
  return { type: REQUEST_CREATE_PIPELINE.REQUEST, meta: { targetPipeline } };
};

export const requestStopPipeline = (pipelineUuid: string) => {
  return { type: REQUEST_STOP_PIPELINE.REQUEST, meta: { pipelineUuid } };
};

export const requestSteps = (stepUuid?: string, pipelineRunUuid?: string) => {
  return { type: REQUEST_STEPS.REQUEST, meta: { stepUuid, pipelineRunUuid } };
};

export const requestLogRange = (
  stepUuid: string,
  pipelineUuid: string,
  callback?: () => void
) => {
  return {
    type: REQUEST_STEP_LOG_RANGE.REQUEST,
    pipelineUuid,
    meta: { callback, stepUuid },
  };
};

export const requestReports = () => {
  return { type: REQUEST_REPORTS.REQUEST, meta: {} };
};

export const requestArtifacts = (pipelineUuid: string, stepUuid: string) => {
  return { type: REQUEST_ARTIFACTS.REQUEST, meta: { pipelineUuid, stepUuid } };
};

export const requestLog = (
  pipelineUuid: string,
  stepUuid: string,
  range = { rangeStart: 0, rangeEnd: MAX_LOG_CHUNK_SIZE },
  meta: {
    index?: number;
    requestCounter?: number;
    requestType?: LogRequestType;
  } = {}
): {
  type: string;
  meta: LogRequestMeta;
} => {
  return {
    type: REQUEST_LOG.REQUEST,
    meta: {
      pipelineUuid,
      stepUuid,
      range,
      requestCounter: meta.requestCounter || 0,
      ...meta,
    },
  };
};

// same as requestLog but it is particular used for dispatch triggered by websocket
export const requestLogThrottled = (
  pipelineUuid: string,
  stepUuid: string,
  range = { rangeStart: 0, rangeEnd: MAX_LOG_CHUNK_SIZE },
  meta: {
    index?: number;
    requestCounter?: number;
    requestType?: LogRequestType;
  } = {}
): {
  type: string;
  meta: LogRequestMeta;
} => {
  return {
    type: REQUEST_LOG_THROTTLED.REQUEST,
    meta: {
      pipelineUuid,
      stepUuid,
      range,
      requestCounter: meta.requestCounter || 0,
      ...meta,
    },
  };
};

export const requestServiceLog = (
  pipelineUuid: string,
  stepUuid: string,
  serviceUuid: string,
  meta = {}
) => {
  return {
    type: REQUEST_SERVICE_LOG.REQUEST,
    meta: { pipelineUuid, stepUuid, serviceUuid, ...meta },
  };
};

export const expandCommand = (stepUuid: string, index: number) => {
  return {
    type: EXPAND_COMMAND,
    meta: {
      stepUuid,
      index,
    },
  };
};

export const collapseCommand = (stepUuid: string, index: number) => {
  return {
    type: COLLAPSE_COMMAND,
    meta: {
      stepUuid,
      index,
    },
  };
};

export const resetDownloadedCommands = () => {
  return {
    type: RESET_DOWNLOADED_COMMANDS,
  };
};

export const requestDeleteLog = (serviceUuid: string) => ({
  type: REQUEST_DELETE_LOG.REQUEST,
  meta: { serviceUuid },
});

export const searchInLog = (search: string) => ({
  type: SEARCH_LOG.REQUEST,
  meta: search,
});

export const searchLogNextLine = () => ({ type: SEARCH_LOG_NEXT_LINE });

export const searchLogPrevLine = () => ({ type: SEARCH_LOG_PREV_LINE });

export const downloadAllCommands = () => ({
  type: DOWNLOAD_ALL_COMMANDS.REQUEST,
});

export const enableLogSearch = () => ({ type: ENABLE_LOG_SEARCH });
export const disableLogSearch = () => ({ type: DISABLE_LOG_SEARCH });

export const requestFailedTestCases = (
  pipelineUuid: string,
  stepUuid: string
) => ({
  type: REQUEST_FAILED_TEST_CASES.REQUEST,
  meta: { pipelineUuid, stepUuid },
});

export const requestTestCaseReason = (
  pipelineUuid: string,
  stepUuid: string,
  testCaseUuid: string
) => ({
  type: REQUEST_TEST_CASE_REASON.REQUEST,
  meta: { pipelineUuid, stepUuid, testCaseUuid },
});

export const expandTestCase = (testCaseUuid: string) => {
  return { type: EXPAND_TEST_CASE, meta: { testCaseUuid } };
};

export const collapseTestCase = (testCaseUuid: string) => {
  return { type: COLLAPSE_TEST_CASE, meta: { testCaseUuid } };
};

export const requestAnnotations = (reportUuid: string) => ({
  type: REQUEST_ANNOTATIONS.REQUEST,
  meta: { reportUuid },
});

export const requestRepositoryFiles = (revision: string) => ({
  type: REQUEST_REPOSITORY_FILES.REQUEST,
  meta: { revision },
});

export const requestCheckSuites = (revision: string) => ({
  type: REQUEST_CHECK_SUITES.REQUEST,
  meta: { revision },
});

export const requestCommitPullRequests = (revision: string) => ({
  type: REQUEST_COMMIT_PULL_REQUESTS.REQUEST,
  meta: { revision },
});

export const setStep = (stepUuid: string) => ({
  type: SET_STEP,
  payload: stepUuid,
});

export const requestMetrics = (pipelineUuid: string, stepUuid: string) => ({
  type: REQUEST_METRICS.REQUEST,
  meta: { pipelineUuid, stepUuid },
});

export const requestStartStep = (
  pipelineUuid: string,
  stepUuid: string,
  stageIndex?: number
) => ({
  type: REQUEST_START_STEP.REQUEST,
  meta: { pipelineUuid, stepUuid, stageIndex },
});

export const requestRedeployStep = (
  pipelineUuid: string,
  stepUuid: string,
  stageIndex?: number
) => ({
  type: REQUEST_REDEPLOY_STEP.REQUEST,
  meta: { pipelineUuid, stepUuid, stageIndex },
});

export const requestResumeStageRedeploy = (
  pipelineUuid: string,
  stageIndex: number
) => ({
  type: REQUEST_RESUME_STAGE_REDEPLOY.REQUEST,
  meta: { pipelineUuid, stageIndex },
});

export const requestStepPromotion = (
  key: string,
  environmentUuid: string,
  pipelineUuid: string,
  stepUuid: string,
  isRerunnableStep: boolean
) => ({
  type: REQUEST_STEP_PROMOTION.REQUEST,
  meta: {
    key,
    environmentUuid,
    isRerunnableStep,
    pipelineUuid,
    stepUuid,
  },
});

export const requestStepRedeployment = (
  key: string,
  environmentUuid: string,
  pipelineUuid: string,
  stepUuid: string
) => ({
  type: REQUEST_STEP_REDEPLOYMENT.REQUEST,
  meta: {
    key,
    environmentUuid,
    pipelineUuid,
    stepUuid,
  },
});

export const requestRerunSteps = (stepUuid?: string) => ({
  type: REQUEST_RERUN_STEPS.REQUEST,
  meta: { stepUuid },
});

export const getJsdChecksDeployments = (deploymentUuids: string[]) => ({
  type: REQUEST_CHECKS_DEPLOYMENTS.REQUEST,
  meta: { deploymentUuids },
});

export const verifyPipelinesInstallation = () => ({
  type: VERIFY_PIPELINES_INSTALLATION,
});
